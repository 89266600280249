











































import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
//import FormData from 'form-data';
import {shared} from "@/views/notes/shared";
import File from '@/components/models/File'
import Tag from '@/components/models/Tag'

@Component({
  props: ['allTags'],
  mixins: [shared]
})
export default class NewFile extends Vue{
  allTags: Tag[] = this.$props.allTags
  name = "NewFile"
  filesToUpload = new Map<File, any>();
  newFileContents: any = ''
  newFile: File = new File("", [], "")
  newFilename = ""
  newTagName = ""
  newTagColor = ""

  addTagToFile(){
    const tag = new Tag(this.newTagName, this.newTagColor)
    this.newFile.tags.push(tag)
    this.allTags.push(tag)
    this.newTagName = ''
  }

  removeTagFromFile(tag: Tag){
    if(this.newFile.tags.length>1) {
      const i = this.newFile.tags.indexOf(tag);
      this.newFile.tags.splice(i, 1);
    }
  }

  allTagsNotOnFile(file: File){
    const t: Tag[] = [];
    this.allTags.forEach(tag=>{
      if(!file.tags.includes(tag)){
        t.push(tag);
      }
    })
    return t;
  }

  handleFile(event: any){
    const f = event.target.files[0]
    if(f!==undefined){
      this.newFileContents = f;
    }
  }

  addFile(){
    //this.newFile.filename=this.newFilename;
    if(this.newFile.filename.length===0){
      this.newFile.filename = this.newFileContents.name;
    }
    if(this.newFile.tags.length>=1){
      this.filesToUpload.set(this.newFile, this.newFileContents);
      this.newFile = new File("", [], "");
      this.newFileContents = '';
    }
  }

  uploadFiles(){
    const data = new FormData();
    for(const key of this.filesToUpload.keys()){
      data.append(key.filename, this.filesToUpload.get(key));
      const tagsList = [];
      for(const tag of key.tags){
        tagsList.push({name: tag.name, color: tag.color});
      }
      data.append(key.filename+"Tags", JSON.stringify(tagsList));
    }
    console.log(this.$data.notesapi);
    axios.post(this.$store.getters.baseurl+"/files/upload",data, {headers: {'authorization': 'Bearer ' + this.$store.getters.token, "Content-Type": "multipart/form-data"}})
        .then(res=>{
          console.log(res);
          this.close();
          this.updateFiles();
        })
        .catch(err=>{
          console.error(err);
        })
  }
  mounted(){
    console.log("mounted:")
    console.log(this.$store.getters.token)
  }

  close(){
    this.$emit('close', true);
  }

  updateFiles(){
    this.$emit('updateFiles', true);
  }

}
