import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Portfolio from '../views/portfolio/Portfolio.vue'
import Tools from '../views/tools/Tools.vue'
import NotFound from '../views/NotFound.vue'
import Notes from '../views/notes/Notes.vue'
import Cv from '../views/cv/Cv_new.vue'
import MinecraftPotions from "@/views/tools/minecraftPotions/MinecraftPotions.vue";
import ShopOptimizer from "@/views/tools/ShopOptimizer.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/tools/minecraftPotions',
    name: 'Minecraft Potions',
    component: MinecraftPotions
  },
  {
    path: '/tools/shopOptimizer',
    name: 'Shop Optimizer',
    component: ShopOptimizer
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes
  },
  {
    path: '/cv',
    name: 'CV',
    component: Cv
  },
  { path: '/404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
