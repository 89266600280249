<template>
  <div class="overlay">
    <div class="modal">
      <button class="close" v-on:click="close()">x</button>
      <h1>Sign up</h1>
      <form name="signup" class="signupform">
        <label>Username : </label><input type="text" class="input" placeholder="username" v-model="username"><br/>
        <label>Password : </label><input type="password" class="input" placeholder="password" v-model="password"><br/>
        <label>Admin password : </label><input type="password" class="input" placeholder="admin password" v-model="adminpassword"><br/><br/>
        <p v-if="errorMessage.length>0" class="errorMessage">{{errorMessage}}</p>
        <input type="button" class="signupbutton button" :disabled="username.length<4 && password.length<3" v-on:click="getToken()" value="Sign up"/>
      </form>
    </div>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import axios from "axios";

@Component({
  props: ['openSignup']
})
export default class Signup extends Vue{
  name = "Signup"
  openSignup = this.$props.openSignup
  username = ""
  password = ""
  adminpassword = ""
  errorMessage = ""
  getToken(){
    const hash = this.password //bcrypt.hashSync(this.password, 10);
    if(this.username.length<4 || this.password.length<3 || this.adminpassword.length<3){
      console.log("no")
    }else{
      axios.post(this.$store.getters.baseurl+"/auth/signup", {username: this.username, password: hash, adminpassword: this.adminpassword}).then(res=>{
        console.log(res.headers)
        if(res.headers.authorization!==undefined) {
          this.$store.commit('setToken', res.headers.authorization.split(' ').pop());
          this.$cookies.set("token", this.$store.getters.token, {expires: "2h"});
          this.close();
          this.updateFiles();
          //this.openSignup = false;
        }
      }).catch((error)=>{
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.errorMessage = error.response.data;
        }else{
          console.log(error.message);
        }
      })
    }
  }

  close(){
    this.$emit('logged', true);
  }

  updateFiles(){
    this.$emit('updateFiles', true);
  }
}
</script>


<style scoped lang="scss">
.overlay{

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;

  .modal{

    width: 500px;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px 3px;
    transition: all 0.2s ease-in;
    font-family: Helvetica, Arial, sans-serif;

    /*width: fit-content;*/
    height: fit-content;
    /*padding: 20px;
    border-radius: 5%;*/

    .close{
      background-color: #f48fb1;
      border: none;
      border-radius: 100%;
      float: right;
    }

    .errorMessage{
      margin: 0;
      color: red;
    }

    .signupform > *{
      margin-top: 5px;
    }

    .signupform{
      input[type=text], input[type=password]{
        max-width: 51ch;
        min-width: 25ch;
        width: auto;
      }

      .input{
        margin-top: 5px;
        margin-bottom: 5px;
        border: none;
        border-bottom: 1px solid #787878;
        outline: none;
      }
      .input:focus{
        border: none;
        border-bottom: 1px solid #f48fb1;
      }

    }

    .button{
      background-color: #f48fb1;
      border-radius: 10px;
      border: none;
      color: #191919;
    }

    .button:disabled{
      background-color: #787878;
    }

  }
}
</style>
