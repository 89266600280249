



















































import {Component, Vue} from "vue-property-decorator";
import {Item, Potion, Wrapper} from "./definitions";
import {potions, items} from "./recipes";

@Component
export default class MinecraftPotions extends Vue{
  //name = "minecraftPotions"
  allPotions = potions;
  potionNames = ["Mundane potion", "Awkward potion", "Thick potion", "Jump Boost", "Jump Boost +", "Jump Boost II", "Fire Resistance", "Fire Resistance +", "Slowness", "Slowness +", "Swiftness", "Swiftness +", "Swiftness II", "Water Breathing", "Water Breathing +", "Healing", "Healing II", "Harming", "Harming II", "Poison", "Poison +", "Poison II", "Night Vision", "Night Vision +", "Invisibility", "Invisibility +", "Regeneration", "Regeneration +", "Regeneration II", "Strength", "Strength +", "Strength II", "Weakness", "Weakness +", "Slow Falling", "Slow Falling +", "Turtle Master", "Turtle Master +", "Turtle Master II"]
  displayedCrafts: Wrapper[] = []
  splash = false
  splashPotion: Potion = new Potion("Splash Potion", items.find(p=>p.name==="Gunpowder"), undefined, undefined, undefined);
  goalPotionName = ''

  craftO(name: string){
    const potion = potions.find(p=>p.name===name);
    if(potion){
      this.craft(potion);
    }
  }

  craft(potion: Potion){
    this.displayedCrafts = [];
    this.goalPotionName = potion.name;
    this.craftPotion(potion);
    this.splashPotion.img = "splash_potion";
    this.splashPotion.basePotion = potion;
    this.splashPotion.color = potion.color;
  }
  
  craftPotion(item: Potion){
    if(item!==undefined){
      //affichage recursif de la potion de base requise 
      if(item.basePotion!==undefined){
        this.craftPotion(item.basePotion);
      }
      //affichage recursif des ingredients
      if(item.ingredient!==undefined){
        this.craftItem(item.ingredient);
      }
      const wrap = new Wrapper();
      wrap.fromPotion(item);
      this.displayedCrafts.push(wrap);
    }
  }
  
  craftItem(item: Item){
    if(item!==undefined){
      const wrap = new Wrapper();
      wrap.fromItem(item);
      this.displayedCrafts.push(wrap);
      //appel recursif des items pour crafter celui ci
      if(item.craft!==undefined){
        if(item.craft.a1!==undefined){
          this.craftItem(item.craft.a1)
        }
        if(item.craft.a2!==undefined){
          this.craftItem(item.craft.a2)
        }
        if(item.craft.a3!==undefined){
          this.craftItem(item.craft.a3)
        }
        if(item.craft.b1!==undefined){
          this.craftItem(item.craft.b1)
        }
        if(item.craft.b2!==undefined){
          this.craftItem(item.craft.b2)
        }
        if(item.craft.b3!==undefined){
          this.craftItem(item.craft.b3)
        }
        if(item.craft.c1!==undefined){
          this.craftItem(item.craft.c1)
        }
        if(item.craft.c2!==undefined){
          this.craftItem(item.craft.c2)
        }
        if(item.craft.c3!==undefined){
          this.craftItem(item.craft.c3)
        }
      }
    }
  }
  
}
