<template>
  <div id="app">
    <TitleBar class="header" v-show="!this.$route.path.includes('cv')"/>
    <router-view class="routerview"/>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBarComponent";

export default {
  components: {
    TitleBar
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  min-width: 659px;
}
</style>
<style lang="scss">
.routerview{
  height: 100%;
}
.footer{
  z-index: 0;
  position: sticky;
  bottom: 0;
}
body{
  margin: 0 !important;
}

@media screen and ( max-width: 880px ) {
  .navigation{
    width: 659px;
    margin: auto;
  }
}
.navigation{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
</style>
