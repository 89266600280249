import {Craft, Item, Potion} from "@/views/tools/minecraftPotions/definitions";

//base items
const carrot = new Item("Carrot", "carrot", undefined);
const goldIngot = new Item("Gold Ingot", "gold_ingot", undefined);
const melon = new Item("Melon", "melon_slice", undefined);
const spiderEye = new Item("Spider Eye", "spider_eye", undefined);
const mushroom = new Item("Mushroom", "brown_mushroom", undefined);
const sugarCane = new Item("Sugar Cane", "sugar_cane", undefined);
const slimeBall = new Item("Slime Ball", "slime_ball", undefined);
const blazeRod = new Item("Blaze Rod", "blaze_rod", undefined);
const redstone = new Item("Redstone", "redstone", undefined);
const netherWart = new Item("Nether Wart", "nether_wart", undefined);
const goldNugget = new Item("Gold Nugget", "gold_nugget", undefined);
const pufferfish = new Item("Pufferfish", "pufferfish", undefined);
const ghastTear = new Item("Ghast Tear", "ghast_tear", undefined);
const phantomMembrane = new Item("Phantom Membrane", "phantom_membrane", undefined);
const scute = new Item("Scute", "scute", undefined);
const gunpowder = new Item("Gunpowder", "gunpowder", undefined);

//crafted items
const sugar = new Item("Sugar", "sugar", new Craft(undefined, undefined, undefined, undefined, sugarCane, undefined, undefined, undefined, undefined));
const goldCarrot = new Item("Gold Carrot", "golden_carrot", new Craft(goldNugget, goldNugget, goldNugget, goldNugget, carrot, goldNugget, goldNugget, goldNugget, goldNugget));
const glisteringMelonSlice = new Item("Glistering Melon Slice", "melon_slice", new Craft(goldNugget, goldNugget, goldNugget, goldNugget, melon, goldNugget, goldNugget, goldNugget, goldNugget));
const fermentedEye = new Item("Fermented Eye", "fermented_spider_eye", new Craft(undefined, undefined, undefined, spiderEye, sugar, mushroom, undefined, undefined, undefined));
const blazePowder = new Item("Blaze Powder", "blaze_powder", new Craft(undefined, undefined, undefined, undefined, blazeRod, undefined, undefined, undefined, undefined));
const magmaCream = new Item("Magma Cream", "magma_cream", new Craft(undefined, undefined, undefined, slimeBall, blazePowder, undefined, undefined, undefined, undefined));
const turtleHelmet = new Item("Turtle Helmet", "scute", new Craft(scute, scute, scute, scute, undefined, scute, undefined, undefined, undefined));

const waterBucket = new Item("Water Bucket", "water_bucket", undefined);
const glowstoneDust = new Item("Glowstone Dust", "glowstone_dust", undefined);
const rabbitFoot = new Item("Rabbit Foot", "rabbit_foot", undefined);

export const items: Item[] =
[
  carrot, goldIngot, melon, spiderEye, mushroom, sugarCane, slimeBall, blazeRod, redstone, netherWart, goldNugget, pufferfish, ghastTear, phantomMembrane, scute,
  sugar, goldCarrot, glisteringMelonSlice, fermentedEye, blazePowder, magmaCream, turtleHelmet, waterBucket, glowstoneDust, rabbitFoot, gunpowder
]

//potions
const water = new Potion("Water Bottle", undefined, undefined, "#0000ff", "no_effect");
const mundane = new Potion("Mundane potion", sugar, water,  "#385dc6", "no_effect");
const awkward = new Potion("Awkward potion", netherWart, water,  "#385dc6", "no_effect");
const thick = new Potion("Thick potion", glowstoneDust, water,  "#385dc6", "no_effect");
const leaping = new Potion("Potion of Jump Boost", rabbitFoot, water,  "#22FF4C", "jump_boost");
const leapingPlus = new Potion("Potion of Jump Boost +", redstone, leaping,  "#22FF4C", "jump_boost");
const leaping2 = new Potion("Potion of Jump Boost II", glowstoneDust, leaping,  "#22FF4C", "jump_boost");
const fire = new Potion("Potion of Fire Resistance", magmaCream, awkward,  "#E49A3A", "fire_resistance");
const firePlus = new Potion("Potion of Fire Resistance +", redstone, fire,  "#E49A3A", "fire_resistance");
const swiftness = new Potion("Potion of Swiftness", sugar, awkward,  "#7CAFC6", "speed");
const swiftnessPlus = new Potion("Potion of Swiftness +", redstone, swiftness,  "#7CAFC6", "speed");
const swiftness2 = new Potion("Potion of Swiftness II", glowstoneDust, swiftness,  "#7CAFC6", "speed");
const slowness = new Potion("Potion of Slowness", fermentedEye, swiftness,  "#7CAFC6", "slowness");
const slownessPlus = new Potion("Potion of Slowness +", redstone, slowness,  "#7CAFC6", "slowness");
const slowness2 = new Potion("Potion of Slowness II", glowstoneDust, slowness,  "#7CAFC6", "slowness");
const waterBreathing = new Potion("Potion of Water Breathing", pufferfish, awkward,  "#2E5299", "water_breathing");
const waterBreathingPlus = new Potion("Potion of Water Breathing +", redstone, waterBreathing,  "#2E5299", "water_breathing");
const healing = new Potion("Potion of Healing", glisteringMelonSlice, awkward,  "#F82423",  "instant_health");
const healing2 = new Potion("Potion of Healing II", glowstoneDust, healing,  "#F82423", "instant_health");
const poison = new Potion("Potion of Poison", spiderEye, awkward,  "#4E9331", "poison");
const poison2 = new Potion("Potion of Poison II", glowstoneDust, poison,  "#4E9331", "poison");
const poisonPlus = new Potion("Potion of Poison +", redstone, poison,  "#4E9331", "poison");
const harming = new Potion("Potion of Harming", fermentedEye, poison,  "#430A09", "instant_damage");
const harming2 = new Potion("Potion of Harming II", glowstoneDust, harming,  "#430A09", "instant_damage");
const night = new Potion("Potion of Night Vision", goldCarrot, awkward,  "#1F1FA1", "night_vision");
const nightPlus = new Potion("Potion of Night Vision +", redstone, night,  "#1F1FA1", "night_vision");
const invisible = new Potion("Potion of Invisibility", fermentedEye, night,  "#7F8392", "invisibility");
const invisiblePlus = new Potion("Potion of Invisibility +", redstone, invisible,  "#7F8392", "invisibility");
const regen = new Potion("Potion of Regeneration", ghastTear, awkward,  "#CD5CAB", "health_boost");
const regen2 = new Potion("Potion of Regeneration II", glowstoneDust, regen,  "#CD5CAB", "health_boost");
const regenPlus = new Potion("Potion of Regeneration +", redstone, regen,  "#CD5CAB", "health_boost");
const strength = new Potion("Potion of Strength", blazePowder, awkward,  "#932423", "strength");
const strength2 = new Potion("Potion of Strength II", glowstoneDust, strength,  "#932423", "strength");
const strengthPlus = new Potion("Potion of Strength +", redstone, strength,  "#932423", "strength");
const weakness = new Potion("Potion of Weakness", fermentedEye, water,  "#484D48", "weakness");
const weaknessPlus = new Potion("Potion of Weakness +", redstone, weakness,  "#484D48", "weakness");
const falling = new Potion("Potion of Slow Falling", phantomMembrane, awkward,  "#FCECCE", "slow_falling");
const fallingPlus = new Potion("Potion of Slow Falling +", redstone, falling,  "#FCECCE", "slow_falling");
const turtle = new Potion("Potion of Turtle Master", turtleHelmet, awkward,  "#55AD21", "absorption");
const turtle2 = new Potion("Potion of Turtle Master II", glowstoneDust, turtle,  "#55AD21", "absorption");
const turtlePlus = new Potion("Potion of Turtle Master +", redstone, turtle,  "#55AD21", "absorption");


export const potions: Potion[] =
[
 /*water,*/ mundane, awkward, thick, leaping, leapingPlus, leaping2, fire, firePlus, swiftness, swiftness2, swiftnessPlus,
 waterBreathing, waterBreathingPlus, healing, healing2, poison, poison2, poisonPlus, harming, harming2, night, nightPlus,
 invisible, invisiblePlus, regen, regenPlus, regen2, strength, strength2, strengthPlus, weakness, weaknessPlus, falling,
 fallingPlus, turtle, turtlePlus, turtle2, slowness, slownessPlus, slowness2
]
