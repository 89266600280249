import { render, staticRenderFns } from "./MinecraftPotions.vue?vue&type=template&id=90216884&scoped=true&"
import script from "./MinecraftPotions.vue?vue&type=script&lang=ts&"
export * from "./MinecraftPotions.vue?vue&type=script&lang=ts&"
import style0 from "./MinecraftPotions.vue?vue&type=style&index=0&id=90216884&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90216884",
  null
  
)

export default component.exports