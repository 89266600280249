export class Craft{
    a1: Item | undefined;
    a2: Item | undefined;
    a3: Item | undefined;
    b1: Item | undefined;
    b2: Item | undefined;
    b3: Item | undefined;
    c1: Item | undefined;
    c2: Item | undefined;
    c3: Item | undefined;
    
    constructor(a1?: Item, a2?: Item, a3?: Item, b1?: Item, b2?: Item, b3?: Item, c1?: Item, c2?: Item, c3?: Item) {
        this.a1=a1;
        this.a2=a2;
        this.a3=a3;
        this.b1=b1;
        this.b2=b2;
        this.b3=b3;
        this.c1=c1;
        this.c2=c2;
        this.c3=c3;
    }
}

export class Wrapper{
    name = '';
    img = '';
    block = '';
    craft: Craft | undefined;
    ingredient: Item | undefined;
    basePotion: Potion | undefined;
    color: string | undefined;
    type = '';

    fromPotion(potion: Potion){
        this.type='potion';
        this.name=potion.name;
        this.img=potion.img;
        this.block=potion.block;

        this.color=potion.color;
        this.basePotion=potion.basePotion;
        this.ingredient=potion.ingredient;

        this.craft = undefined;
    }

    fromItem(item: Item){
        this.type='item';
        this.name=item.name;
        this.img=item.img;
        this.block=item.block;

        this.craft = item.craft;

        this.color=undefined;
        this.basePotion=undefined;
        this.ingredient=undefined;

    }

}

export class Item{
    craft: Craft | undefined;
    img: string;
    name: string;
    block = "crafting_table";

    constructor(name: string, img: string, craft?: Craft) {
        this.img=img;
        this.name=name;
        this.craft=craft;
    }
}

export class Potion{
    name: string;
    ingredient: Item | undefined;
    basePotion: Potion | undefined;
    img = "potion";
    color: string;
    block = "brewing_stand";
    effect: string | undefined;

    constructor(name: string, ingredient?: Item, basePotion?: Potion, color?: string, effect?: string) {
        this.name=name;
        this.ingredient=ingredient;
        this.basePotion=basePotion;
        this.color=(color==undefined)? "#ffff00" : color;
        this.effect=effect;
    }

}
