<template>
  <div class="tools navigation">
    <div class="container">
      <router-link class="route" to="/tools/minecraftPotions"><i class="material-icons">coffee</i>{{$t('toolsView.linkPotions')}}</router-link>
    </div>
    <div class="container">
      <router-link class="route" to="/tools/shopOptimizer"><i class="material-icons">shopping_bag</i>{{$t('toolsView.linkShopOptimizer')}}</router-link>
    </div>
  </div>
</template>

<script>
export default{
  name: "Tools"
}
</script>

<style scoped>
.tools{
  height: 100%;
}
.container{
  background-color: #4e4e4e;
  display: inline-block;
  width: 200px;
  height: 200px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  transition: background-color 0.1s ease-in-out;
}
.container:hover{
  background-color: rgba(244, 143, 177, 0.8);
  transition: background-color 0.1s ease-in-out;
}
.route{/*link*/
  display: inline-block;
  height: 100%;
  width: 100%;
  margin-top: auto;
  line-height: 0;
}
.material-icons{
  font-size: 100px !important;
  width: 100%;
  line-height: 150px;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}
a {
  color: #ffffff;
}
</style>
