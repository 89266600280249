










































































































































































































import {Component, Vue} from "vue-property-decorator";

@Component
export default class CV extends Vue{
  name = "cv"
}
