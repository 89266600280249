














































































import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import File from '@/components/models/File'
import Tag from '@/components/models/Tag'
import NewFile from "@/views/notes/NewFile.vue";
import {shared} from "@/views/notes/shared";
import Login from "@/views/notes/Login.vue";
import Signup from "@/views/notes/Signup.vue";

@Component({
  components: {Signup, Login, NewFile},
  mixins: [shared]
})
export default class Notes extends Vue {
  openUpdateFile = false
  openUploadFile = false
  openLogin = false
  openSignup = false
  rawFile = false
  newTagName = ""
  newTagColor = ""
  fileToEdit: File = new File("", [], "")
  name = "Notes"
  fileData = "<p>Select a file on the left and its contents will be displayed here.</p>"
  allTags: Tag[] = []
  allFiles: File[] = []
  displayedFiles: File[] = []

  /*
   * DISPLAY METHODS
   */
  addTagToFile(){
    const tag = new Tag(this.newTagName, this.newTagColor)
    this.fileToEdit.tags.push(tag)
    this.allTags.push(tag)
    this.updateDisplayedFiles()
  }

  removeTagFromFile(tag: Tag){
    if(this.fileToEdit.tags.length>1) {
      const i = this.fileToEdit.tags.indexOf(tag);
      this.fileToEdit.tags.splice(i, 1);
      this.updateListOfAllTags();
    }
  }

  allTagsNotOnFile(file: File){
    const t: Tag[] = [];
    this.allTags.forEach(tag=>{
      if(!file.tags.includes(tag)){
        t.push(tag);
      }
    })
    return t;
  }

  updateListOfAllTags(){
    const oldTags: Tag[] = []
    this.allTags.forEach(tag=>oldTags.push(tag));//copy
    this.allTags = []
    this.allFiles.forEach(file=>{
      file.tags.forEach(tag=> {
        if(!this.allTags.find(t=>t.name===tag.name)) {
          this.allTags.push(tag)
        }
      })
    })
  }

  toggleTagFilter(tag: Tag){
    tag.selected=!tag.selected
    this.updateDisplayedFiles();
  }

  updateDisplayedFiles(){
    this.displayedFiles = []
    this.allFiles.forEach(f=>this.displayedFiles.push(f))
    this.allTags.forEach(tag=>{
      if(!tag.selected) {
        this.allFiles.forEach(file => {
          if (file.tags.find(ft => ft.name === tag.name)!==undefined) {
            const i = this.displayedFiles.indexOf(file)
            this.displayedFiles.splice(i, 1)
          }
        })
      }
    })
  }


  /*
   * METHODS USING API
   */
  updateFile(file: File){
    console.log("file to update:")
    console.log(file)
    //send post request
    console.log("updated file:")
    console.log(file)
  }

  getFilesList(){
    this.allFiles = [];
    this.allTags = [];
    axios.get(this.$store.getters.baseurl+"/files",
        {headers: {'authorization':'Bearer '+this.$store.getters.token}}).then(res=>{
      for(const obj of res.data){
        const tags: Tag[] = []
        for(const t of obj.tags){
          tags.push(new Tag(t.name, t.color));
        }
        console.log(obj.mimetype);
        this.allFiles.push(new File(obj.filename, tags, obj.mimetype));
      }
      this.allFiles.forEach(file=>{
        file.tags.forEach(filetag=> {
          if(!this.allTags.find(tag=>tag.name===filetag.name)){
            this.allTags.push(filetag)
          }
        })
      })
      this.updateDisplayedFiles()
    }).catch(err=>console.error(err));
  }

  getFile(filename: string){
    axios.get(this.$store.getters.baseurl+'/files/'+filename+'/content-type', {headers:{'authorization':'Bearer '+this.$store.getters.token}})
    .then(res => {
      const contenttype: string = res.headers['content-type'];
      const matches = contenttype.match(/(\w*)\/(\w*)/);
      if(matches!=null) {
        const left = matches[1];
        const right = matches[2];
        switch (left) {
          case "text":
            axios.get(this.$store.getters.baseurl + "/files/" + filename, {
              params: {convert: !this.rawFile},
              headers: {'authorization': 'Bearer ' + this.$store.getters.token}
            })
                .then(res => {
                  if(right==="markdown"){
                    this.fileData = res.data;
                  }else if(right==="html"){
                    this.fileData = res.data;
                  }else{
                    this.fileData = "<code>"+res.data+"</code>";
                  }
                })
                .catch(err => console.error(err))
            break;
          case "image": {
            axios.get(this.$store.getters.baseurl+"/files/"+filename, {responseType: "arraybuffer" , headers:{'authorization':'Bearer '+this.$store.getters.token}})
                .then(res => {
                  const image = "data:"+contenttype+";base64,"+Buffer.from(res.data, 'binary').toString('base64');

                  this.fileData = "<img src='"+image+"' class='fullFile' alt='raté' style='max-width: 100%; max-height: 100%;' />";
                })
                .catch(err=>console.error(err))
            break;
          }
          case "application":
            if (right === "pdf") {
              axios.get(this.$store.getters.baseurl+"/files/"+filename, {responseType: "arraybuffer" , headers:{'authorization':'Bearer '+this.$store.getters.token}})
                  .then(res => {
                    const image = "data:"+contenttype+";base64,"+Buffer.from(res.data, 'binary').toString('base64');

                    this.fileData = "<iframe class='fullFile' src='"+image+"'/>";
                  })
                  .catch(err=>console.error(err))
            } else {
              this.fileData = "<p>file type not handled yet</p>";
            }
            break;
          default:
            this.fileData = "<p>file type not handled yet</p>";
            break;
        }
      }
    })
    .catch(err=>{console.error(err)})
  }

  logout(){
    console.debug("logout")
    this.allFiles = [];
    this.allTags = [];
    this.updateDisplayedFiles();
    this.updateListOfAllTags();
    this.$store.commit('setToken', '');
    this.$cookies.remove('token');
  }

  mounted() {
    if(this.$cookies.isKey('token') && this.$cookies.get('token') != undefined){
      this.$store.commit('setToken', this.$cookies.get('token') as string);
      console.debug("found cookie:"+this.$store.getters.token);
      this.getFilesList()
    }
  }

}
