<template>
  <div class="projetBTS article portfolioProjetBTSComponent">
    <h2 class="sectionTitle">{{ $t('portfolioView.btsComponent.title') }}</h2>
    <img src="@/assets/undraw/undraw_programmer_imem.svg" class="illustration" about="undraw.co" alt="illustration" title="undaw.co">
    <p>{{ $t('portfolioView.btsComponent.p1') }} <a href="https://phodia.fr">Phodia</a>{{ $t('portfolioView.btsComponent.p1bis') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_diag_cas.png" about="$t('portfolioView.btsComponent.fig1.img')" alt="$t('portfolioView.btsComponent.fig1.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_diag_cas.png" target="_blank">{{$t('portfolioView.btsComponent.fig1.link')}}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p2') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_diag_deploiement.png" :about="$t('portfolioView.btsComponent.fig2.img')" :alt="$t('portfolioView.btsComponent.fig2.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_diag_deploiement.png" target="_blank">{{$t('portfolioView.btsComponent.fig2.link')}}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_schema.png" :about="$t('portfolioView.btsComponent.fig3.img')" :alt="$t('portfolioView.btsComponent.fig3.img')">
      <figcaption>
        <a href="@/assets/portfolio/BTS/projetBTS_schema.png" target="_blank">{{$t('portfolioView.btsComponent.fig3.link')}}</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p3') }}</p>
    <p>{{ $t('portfolioView.btsComponent.p4') }}</p>
    <figure class="figs" style="width: auto !important; max-height: 100% !important;">
      <img src="@/assets/portfolio/BTS/projetBTS_test_serie.png" :about="$t('portfolioView.btsComponent.fig4.img')"
           :alt="$t('portfolioView.btsComponent.fig4.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_test_serie.png" target="_blank">{{$t('portfolioView.btsComponent.fig4.link')}}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <p>{{$t('portfolioView.btsComponent.p5')}}</p>
    <table>
      <tr>
        <th>{{$t('portfolioView.btsComponent.table.headers.A1')}}</th>
        <th>{{$t('portfolioView.btsComponent.table.headers.A2')}}</th>
        <th>{{$t('portfolioView.btsComponent.table.headers.A3')}}</th>
        <th>{{$t('portfolioView.btsComponent.table.headers.A4')}}</th>
      </tr>
      <tr>
        <td>SQLite3</td>
        <td>{{$t('portfolioView.btsComponent.table.types.lib')}}</td>
        <td><i class="material-icons md-15 green-check">check</i></td>
        <td><a href="https://www.sqlite.org/">sqlite.org</a></td>
      </tr>
      <tr>
        <td>Curl + OpenSSL</td>
        <td>{{$t('portfolioView.btsComponent.table.types.lib')}}</td>
        <td><i class="material-icons md-15 green-check">check</i></td>
        <td><a href="https://curl.haxx.se/">curl.haxx.se</a></td>
      </tr>
      <tr>
        <td>GPhoto2</td>
        <td>{{$t('portfolioView.btsComponent.table.types.lib')}}</td>
        <td><i class="material-icons md-15 green-check">check</i></td>
        <td><a href="http://www.gphoto.org/">gphoto.org</a></td>
      </tr>
      <tr>
        <td>SeriaLib</td>
        <td>{{$t('portfolioView.btsComponent.table.types.class')}}</td>
        <td><i class="material-icons md-15 green-check">check</i></td>
        <td><a href="https://lucidar.me/fr/serialib/cross-plateform-rs232-serial-library/">lucidar.me</a></td>
      </tr>
    </table>
    <p>{{ $t('portfolioView.btsComponent.p6') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_diag_classe.png" :about="$t('portfolioView.btsComponent.fig5.img')" :alt="$t('portfolioView.btsComponent.fig5.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_diag_classe.png" target="_blank">{{$t('portfolioView.btsComponent.fig5.link')}}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p7') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_bdd.png" :about="$t('portfolioView.btsComponent.fig6.img')" :alt="$t('portfolioView.btsComponent.fig6.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_bdd.png" target="_blank">{{ $t('portfolioView.btsComponent.fig6.link') }}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p8') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_screenPC.png" about="$t('portfolioView.btsComponent.fig7.img')" alt="$t('portfolioView.btsComponent.fig7.img')">
      <figcaption><a href="@/assets/portfolio/BTS/projetBTS_screenPC.png" target="_blank">{{ $t('portfolioView.btsComponent.fig7.link') }}</a><i class="material-icons md-15">launch</i></figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p9') }}</p>
    <p>{{ $t('portfolioView.btsComponent.p10') }}</p>
    <p>{{ $t('portfolioView.btsComponent.p11') }}</p>
    <p>{{ $t('portfolioView.btsComponent.p12') }}</p>
    <figure class="figs">
      <img src="@/assets/portfolio/BTS/projetBTS_algo.png" :about="$t('portfolioView.btsComponent.fig8.img')" :alt="$t('portfolioView.btsComponent.fig8.img')">
      <figcaption>
        <a href="@/assets/portfolio/BTS/projetBTS_algo.png" target="_blank">{{$t('portfolioView.btsComponent.fig8.link')}}</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <p>{{ $t('portfolioView.btsComponent.p13') }}</p>
    <img src="@/assets/doublevictorianBcg.png" class="separator" about="" alt="separateur">
  </div>
</template>

<script>
export default {
  name: "PortfolioProjetBTSComponent"
}
</script>


<style lang="scss" scoped>
.projetBTS{

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    
    vertical-align: baseline;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /****/
  *{
    box-sizing: border-box;
    z-index: 1;
  }
  a{
    text-decoration: none;
  }

  a:hover{
    text-decoration: dotted #f48fb1 ;
  }
  a, a:visited, a:active{
    color: #F48FB1 ;
  }

  .material-icons.md-15{
    font-size: 15px;
    color: #f48fb1;
    vertical-align: middle;
  }
  .figs{
    margin-top: 10px;
  }
  .figs > img{
    width: auto;
    max-width: 100%;
    z-index: 1 !important;
  }
  .figs > figcaption > a{
    width: auto;
    border-bottom: solid 1px #f48fb1;
  }
  .illustration{
    min-width: 100px;
    /*width:25%;*/
    max-width: 400px;
    max-height: 150px;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  table{
    margin: 10px auto 16px;
    max-width: 100%;
    overflow-y: auto;
  }
  td, th{
    padding: 3px;
    border: #191919 solid 1px;
  }
  pre{
    min-width: 50%;
    width: auto;
    max-width: 80%;
    text-align: left;
    margin: auto;
    word-wrap: break-word;
    overflow-y: auto;
  }
  .sectionTitle{
    font-size: 2.5em;
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .separator{
    width: auto;
    opacity: 0.75;
    margin-top: 20px;
  }
  .green-check{
    color: #00b000 !important;
  }
  .nocode, .footer{
    color: #F9F9F9;
  }

  h3{
    font-size: 1.4em;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 768px) {/*1025*/

    .article{
      min-height: 100vh;
      min-width: 720px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .article{
      min-height: 100vh;
      min-width: 50vw;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 767px) {

    .article{
      text-align: center;
      text-indent: 2em;
    }
    .illustration{
      background-color: rgba(255, 255, 255, 0);
      min-width: 100px;
      width:50%;
      max-width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .aside{
      display: block;
      min-height: 35px;
      height: auto;
      max-height: 360px;
      width: 100vw;
      position: sticky;
      text-align: center;
      padding-bottom: 5px ;
      margin: 0 ;
      background-color: #191919;
      top: 0;
      left: 0;
      z-index: 9;
    }

    .article{
      text-align: center;
      min-height: 88vh;
      width: 100%;
      margin: 0;
      grid-row: 1/1;
      padding: 0 12px 13px;
    }

    pre{
      min-width: 50%;
      width: auto;
      max-width: 95%;
      max-height: 400px;
      text-align: left;
      margin: auto;
    }
    .separator{
      max-height: 20px;
    }
    .article:first-of-type > .sectionTitle{
      padding-top: 0;
    }
    .sectionTitle{
      padding-top: 25px;
    }
    tr{
      min-width: 150px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }


  }
  .article>p:not(.text-center){
    text-indent: 20px;
    text-align: justify !important;
  }
}
</style>
