
export const shared = {
    methods: {
        calculateFontColor: (hexa: string)=>{
            const threshold = 0.5;
            const regex = new RegExp(/^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/)
            const matches = regex.exec(hexa)
            if(matches===null){
                return 1
            }
            if(matches.length<4){
                return 1
            }

            const r = (matches[1])?parseInt(matches[1], 16) * 0.2126 : 1
            const g = (matches[2])?parseInt(matches[2], 16) * 0.7152 : 1
            const b = (matches[3])?parseInt(matches[3], 16) * 0.0722 : 1

            return ((r+g+b)/255-threshold)
        }
    },
    data: function () {
        return {
            notesapi: {
                baseurl: (process.env.NODE_ENV === "dev") ? "http://localhost" : "https://benjamindanlos.fr/notesapi"
            }
        }
    }
}
