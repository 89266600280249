import Tag from './Tag'

export default class File{
    filename: string
    tags: Tag[]
    mimetype: string
    constructor(filename: string, tags: Tag[], mimetype: string) {
        this.filename=filename;
        this.tags=tags;
        this.mimetype=mimetype;
    }
}
