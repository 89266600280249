































import {Component, Vue, Watch} from "vue-property-decorator"


@Component
export default class TitleBarComponent extends Vue{
  name = "TitleBar"
  currentPath: JSON

  constructor() {
    super();
    this.currentPath = JSON.parse('{"home":"/"}')
  }

  scrollToTop(){
    window.scrollTo(0,0)
  }

  @Watch('$route', { immediate: true, deep: true })
  updatePath(){
    let s = '{"home":"/"'
    this.$route.fullPath.split("/").forEach((e)=>{
      if(e!=="" && !e.includes("404")) {
        const i = this.$route.fullPath.indexOf(e)
        const intermediatePath = this.$route.fullPath.slice(0,i+e.length)
        s += ',"'+e+'":"'+intermediatePath+'"'
      }
    })
    s+='}'
    console.log(s)
    this.currentPath = JSON.parse(s)
  }
}
