<template>
  <div class="astro article portfolioAstroComponent">
    <h2 class="sectionTitle">{{$t('portfolioView.astroComponent.title')}}</h2>
    <img src="@/assets/undraw/undraw_lost_online_wqob.svg" class="illustration" about="undraw.co" alt="illustration" title="undaw.co">
    <h3>{{$t('portfolioView.astroComponent.presentation')}}</h3>
    <p>{{$t('portfolioView.astroComponent.p1')}}</p>
    <p>{{$t('portfolioView.astroComponent.p2')}}<a href="https://vizier.u-strasbg.fr/viz-bin/VizieR"> VizieR</a>. {{$t('portfolioView.astroComponent.p3')}}</p>
    <p>{{$t('portfolioView.astroComponent.p4')}}
      <!--<pre class="prettyprint">
          <code class="nocode">
224  Gx  0 42.7  +41 16 s  And 178.    3.5  !!! eeB, eL, vmE (Andromeda); = M31
--------------------------------------------------------------------------------
Bytes Format  Units   Label    Explanations
--------------------------------------------------------------------------------
1-  5  A5     ---     Name     NGC or IC designation (preceded by I)
7-  9  A3     ---     Type     Object classification (1)
11- 12  I2     h       RAh      Right Ascension B2000 (hours)
14- 17  F4.1   min     RAm      Right Ascension B2000 (minutes)
20  A1     ---     DE-      Declination B2000 (sign)
21- 22  I2     deg     DEd      Declination B2000 (degrees)
24- 25  I2     arcmin  DEm      Declination B2000 (minutes)
27  A1     ---     Source   Source of entry (2)
30- 32  A3     ---     Const    Constellation
33  A1     ---     l_size   [<] Limit on Size
34- 38  F5.1   arcmin  size     ? Largest dimension
41- 44  F4.1   mag     mag      ? Integrated magnitude, visual or photographic
                                (see n_mag)
45  A1     ---     n_mag    [p] 'p' if mag is photographic (blue)
47- 96  A50    ---     Desc     Description of the object (3)
--------------------------------------------------------------------------------
                   !!!      a magnificent or otherwise interesting object
--------------------------------------------------------------------------------
          </code>
      </pre>-->
    </p>
    <figure class="figs">
      <img src="@/assets/portfolio/astro/catDescription.png" :about="$t('portfolioView.astroComponent.fig1.img')"
           :alt="$t('portfolioView.astroComponent.fig1.img')">
      <figcaption>
        <a href="@/assets/portfolio/astro/catDescription.png" target="_blank">{{$t('portfolioView.astroComponent.fig1.link')}}</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <h3>{{$t('portfolioView.astroComponent.science')}}</h3>
    <p>{{$t('portfolioView.astroComponent.p5')}}</p>
    <p>{{$t('portfolioView.astroComponent.p6')}}</p>
    <p>\[LST=99.967794687+360.98564736628693\times \Delta J +2.907879\times 10^{-13} \times \Delta J ^2 -5.302*10^{-22} \times \Delta J ^3 + \lambda\]</p>
    <p>{{$t('portfolioView.astroComponent.p7')}}</p>
    <p>\[HA=LST-RA\]</p>
    <p>{{$t('portfolioView.astroComponent.p8')}}</p>
    <p>{{$t('portfolioView.astroComponent.p9')}}</p>
    <p>\[ALT=atan2 \left( \frac{cos(HA) \times cos(DEC) \times cos( \lambda) + sin(DEC)  \times  sin( \lambda)}{\sqrt{(-cos(HA) \times cos(DEC) \times sin( \lambda) \times sin(DEC) \times cos( \lambda))^2+(-sin(HA) \times cos(DEC))^2}} \right) \]</p>
    <p>\[AZ=atan2 \left( \frac{-sin(HA)\times cos(DEC)}{-cos(HA)\times cos(DEC) \times sin(\lambda) \times sin(DEC) cos(\lambda)} \right )\]</p>
    <p>{{$t('portfolioView.astroComponent.p10')}}</p>
    <h3>{{$t('portfolioView.astroComponent.programmation')}}</h3>
    <p>{{$t('portfolioView.astroComponent.p11')}}</p>
    <img src="@/assets/doublevictorianBcg.png" class="separator" about="" alt="separateur">
  </div>
</template>

<script>
export default {
  name: "PortfolioAstroComponent",
  mounted() {
    const codePrettyfy = document.createElement("script")
    codePrettyfy.setAttribute('src', 'https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js')
    document.head.appendChild(codePrettyfy)
    const mathJax = document.createElement("script")
    mathJax.setAttribute('src', 'https://polyfill.io/v3/polyfill.min.js?features=es6')
    document.head.appendChild(mathJax)
    const mathJaxScript = document.createElement("script")
    mathJaxScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js')
    document.head.appendChild(mathJaxScript)
  }
}
</script>
<style lang="scss" scoped>
.astro{
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;

    vertical-align: baseline;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /****/
  *{
    box-sizing: border-box;
    z-index: 1;
  }
  a{
    text-decoration: none;
  }

  a:hover{
    text-decoration: dotted #f48fb1 ;
  }
  a, a:visited, a:active{
    color: #F48FB1 ;
  }

  .material-icons.md-15{
    font-size: 15px;
    color: #f48fb1;
    vertical-align: middle;
  }
  .figs{
    margin-top: 10px;
  }
  .figs > img{
    width: auto;
    max-width: 100%;
    z-index: 1 !important;
  }
  .figs > figcaption > a{
    width: auto;
    border-bottom: solid 1px #f48fb1;
  }
  .illustration{
    min-width: 100px;
    /*width:25%;*/
    max-width: 400px;
    max-height: 150px;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  table{
    margin: 10px auto 16px;
    max-width: 100%;
    overflow-y: auto;
  }
  td, th{
    padding: 3px;
    border: #191919 solid 1px;
  }
  pre{
    min-width: 50%;
    width: auto;
    max-width: 80%;
    text-align: left;
    margin: auto;
    word-wrap: break-word;
    overflow-y: auto;
  }
  .sectionTitle{
    font-size: 2.5em;
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .separator{
    width: auto;
    opacity: 0.75;
    margin-top: 20px;
  }
  .green-check{
    color: #00b000 !important;
  }
  .nocode, .footer{
    color: #F9F9F9;
  }

  h3{
    font-size: 1.4em;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 768px) {/*1025*/

    .article{
      min-height: 100vh;
      min-width: 720px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .article{
      min-height: 100vh;
      min-width: 50vw;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 767px) {

    .article{
      text-align: center;
      text-indent: 2em;
    }
    .illustration{
      background-color: rgba(255, 255, 255, 0);
      min-width: 100px;
      width:50%;
      max-width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .aside{
      display: block;
      min-height: 35px;
      height: auto;
      max-height: 360px;
      width: 100vw;
      position: sticky;
      text-align: center;
      padding-bottom: 5px ;
      margin: 0 ;
      background-color: #191919;
      top: 0;
      left: 0;
      z-index: 9;
    }

    .article{
      text-align: center;
      min-height: 88vh;
      width: 100%;
      margin: 0;
      grid-row: 1/1;
      padding: 0 12px 13px;
    }

    pre{
      min-width: 50%;
      width: auto;
      max-width: 95%;
      max-height: 400px;
      text-align: left;
      margin: auto;
    }
    .separator{
      max-height: 20px;
    }
    .article:first-of-type > .sectionTitle{
      padding-top: 0;
    }
    .sectionTitle{
      padding-top: 25px;
    }
    tr{
      min-width: 150px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }


  }
  .article>p:not(.text-center){
    text-indent: 20px;
    text-align: justify !important;
  }
}
</style>
