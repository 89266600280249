















import {Component, Vue} from "vue-property-decorator";
import axios from "axios";

@Component({
  props: ['openLogin']
})
export default class Login extends Vue{
  name = "Login"
  openLogin = this.$props.openLogin
  username = ""
  password = ""
  errorMessage = ""
  getToken(){
    const hash = this.password //bcrypt.hashSync(this.password, 10);
    if(this.username.length<4 || this.password.length<3){
      this.openLogin=true;
    }else{
      axios.post(this.$store.getters.baseurl+"/auth/login", {username: this.username, password: hash}).then(res=>{
        console.log(res.headers)
        if(res.headers.authorization!==undefined) {
          this.$store.commit('setToken', res.headers.authorization.split(' ').pop());
          this.$cookies.set("token", this.$store.getters.token, {expires: "2m"});
          this.close();
          this.updateFiles();
        }
      }).catch((error)=>{
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.errorMessage = error.response.data;
        }else{
          console.log(error.message);
        }
      })
    }
  }

  close(){
    this.$emit('logged', true);
  }

  updateFiles(){
    this.$emit('updateFiles', true);
  }
}
