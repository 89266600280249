import { render, staticRenderFns } from "./ShopOptimizer.vue?vue&type=template&id=fddf6576&scoped=true&"
import script from "./ShopOptimizer.vue?vue&type=script&lang=ts&"
export * from "./ShopOptimizer.vue?vue&type=script&lang=ts&"
import style0 from "./ShopOptimizer.vue?vue&type=style&index=0&id=fddf6576&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fddf6576",
  null
  
)

export default component.exports