




























































import {Component, Vue} from "vue-property-decorator";

class Item{
  //id: number
  name: string;
  price: number;
  value: number;
  link: string;

  constructor(name: string, price: number, value: number, link: string) {
    this.name=name;
    this.price=price;
    this.value=value;
    this.link=link;
  }

}

interface Knapsack {
  items: Item[];
  weight: number;
  value: number;
}

@Component
export default class ShopOptimizer extends Vue{
  itemsList: Item[] = [];
  optimizedItems: Item[] = [];
  budget = 0;
  newValue = 0;
  newName = "";
  newPrice = 0;
  newLink = '';
  total = 0;
  currency = '';
  availableCurrencies = ['€', '$', '£'];
  filename = '';

  add(){
    const item = new Item(this.newName, this.newPrice, this.newValue, this.newLink);
    this.itemsList.push(item)
    this.newName="";
    this.newPrice=0;
    this.newValue=0;
    this.newLink="";
    this.saveCookie();
  }

  remove(item: Item){
    const i = this.itemsList.indexOf(item);
    this.itemsList.splice(i, 1);
    this.saveCookie();
  }

  exportJson(): string {
    const json = {
      "budget": this.budget,
      "currency": this.currency,
      "items": [] as Item[]
    };
    this.itemsList.forEach(item=>{
      json.items.push(item);
    })
    return JSON.stringify(json);
  }

  loadJson(e: Event){
    const el = e.target as HTMLInputElement;
    if(el!=null && el.files!=null) {
      const file = el.files[0];
      const reader = new FileReader();
      if (file.name.endsWith(".json")) {
        reader.onload = (res) => {
          if (res !== null && res.target !== null) {
            let content = res.target.result;
            if(content==null){
              content='';
            }
            const json: {budget: number; currency: string; items: Item[]} = JSON.parse(content.toString());
            this.itemsList = [];
            this.budget = json.budget;
            this.currency = json.currency;
            json.items.forEach((item) => {
              if(typeof item.price!=="number"){
                item.price = parseFloat(item.price);
              }
            })
            this.itemsList = json.items;
            this.saveCookie();
          }
        };
        reader.onerror = (err) => console.error(err);
        reader.readAsText(file);
      }
      el.type='text';
      el.type='file';
    }
  }

  saveCookie(){
    this.$cookies.set('shopOptimizer', this.exportJson());
  }

  loadCookie(){
    const cookie=this.$cookies.get('shopOptimizer');
    if(cookie!=null){
      const savedItems: {budget: number; currency: string; items: Item[]} = JSON.parse(JSON.stringify(cookie));
      for (const item of savedItems.items) {
        if(typeof item.price!=="number"){
          item.price = parseFloat(item.price);
        }
        this.itemsList.push(item);
      }
      this.budget = parseFloat(savedItems.budget.toString());
      this.currency = savedItems.currency.toString();
    }
  }

  mounted(){
    this.currency=this.availableCurrencies[0];
    this.loadCookie();
  }

  calculate(){
    if(this.budget===0){
      alert("Budget not set");
      return;
    }
    this.itemsList.forEach(item=>{
      if(item.price==null || item.price<=0){
        delete item.price;
        alert("Prices cannot be zero or negative.");
        throw new DOMException("Prices cannot be zero or negative.");
      }
      if(typeof item.price!=="number"){
        item.price = parseFloat(item.price);
      }
    })
    const res = this.knapsack(this.itemsList, this.budget, this.itemsList.length-1);
    this.optimizedItems = [];
    this.optimizedItems = res.items;
    this.total=0;
    this.optimizedItems.forEach(item => {
      this.total += item.price
    });
  }

  knapsack(items: Item[], cap: number, itemIndex: number): Knapsack {
    if (cap === 0){
      return {
        items: [],
        value: 0,
        weight: 0
      };
    }
    if(itemIndex < 0) {
      return {
        items: [],
        value: 0,
        weight: 0
      };
    }

    if (itemIndex > -1 && items[itemIndex].price > cap) {
      return this.knapsack(items, cap, itemIndex - 1);
    }

    const sackWithItem = this.knapsack(
        items,
        cap - items[itemIndex].price,
        itemIndex - 1
    );

    const sackWithoutItem = this.knapsack(items, cap, itemIndex - 1);

    const valueWithItem = sackWithItem.value + items[itemIndex].value;
    const valueWithoutItem = sackWithoutItem.value;
    if (valueWithItem > valueWithoutItem) {
      return {
        items: sackWithItem.items.concat(items[itemIndex]),
        value: valueWithItem,
        weight: sackWithItem.weight + items[itemIndex].price
      };
    } else {
      return sackWithoutItem;
    }
  }
}
