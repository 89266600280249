import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueCookies from 'vue-cookies-ts'
import Vuex from 'vuex'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueCookies);

const store = new Vuex.Store({
  state: {
    baseurl: (process.env.NODE_ENV === "dev") ? "http://localhost" : "https://benjamindanlos.fr/notesapi",
    token: ''
  },
  mutations: {
    setToken(state, token: string){
      state.token = token;
    }
  },
  getters:{
    token(state){
      return state.token;
    },
    baseurl(state){
      return state.baseurl;
    }
  }
})

new Vue({
  router,
  i18n,
  store: store,
  render: h => h(App)
}).$mount('#app')
