<template>
  <div class="main">
    <img src="@/assets/undraw/undraw_page_not_found_su7k.svg" class="illustration" about="undraw.co"
         alt="illustration"
         title="undaw.co">
    <h1>{{$t('notFound.message')}}</h1>
    <router-link to="/">{{$t('notFound.linkHome')}}</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
.main{
  text-align: center;
  flex-direction: column;
  padding-top: 10px;
  height: 100%;
}
.illustration{
  width: auto;
  margin: auto;
}
h1, a, a:hover, a:visited{
  text-align: center;
  margin: auto;
  width: 100%;
  text-decoration: none;
}
@media (min-width: 768px){
  .illustration{
    max-width: 90%;
  }
}
@media (max-width: 767px){
  .illustration{
    max-width: 500px;
  }
}
</style>