



































































































import { Component, Vue } from 'vue-property-decorator';
import PortfolioSiteComponent from "@/views/portfolio/PortfolioSiteComponent.vue";
import PortfolioProjetBacComponent from "@/views/portfolio/PortfolioProjetBacComponent.vue";
import PortfolioTelemetrieF1Component from "@/views/portfolio/PortfolioTelemetrieF1Component.vue";
import PortfolioModbusComponent from "@/views/portfolio/PortfolioModbusComponent.vue";
import PortfolioProjetBTSComponent from "@/views/portfolio/PortfolioProjetBTSComponent.vue";
import PortfolioAstroComponent from "@/views/portfolio/PortfolioAstroComponent.vue";
import PortfolioScanExamComponent from "@/views/portfolio/PortfolioScanExamComponent.vue";

@Component({
  components: {
    PortfolioScanExamComponent,
    PortfolioAstroComponent,
    PortfolioProjetBTSComponent,
    PortfolioModbusComponent,
    PortfolioTelemetrieF1Component,
    PortfolioProjetBacComponent,
    PortfolioSiteComponent,
  },
})
export default class Portfolio extends Vue {
  menuOpened = false;
  menuIcon = "menu";
  toggleMenu(){
    console.log(this.menuOpened)
    if(this.menuOpened){
      this.menuIcon = "menu"
    }else{
      this.menuIcon = "clear"
    }
    this.menuOpened = !this.menuOpened
  }
  scrollTo(refName: string) {
    const element = this.$refs[refName] as Element;
    element.scrollIntoView();
  }

}
