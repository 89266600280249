<template>
  <div class="scanexam article portfolioScanExamComponent">
    <h2 class="sectionTitle">ScanExam</h2>
    <img src="@/assets/portfolio/scanexam/ScanExamLogo.png" class="illustration" :about="$t('portfolioView.scanexamComponent.logo')" :alt="$t('portfolioView.scanexamComponent.logo')" :title="$t('portfolioView.scanexamComponent.logo')">
    <p>ScanExam est un projet uniersitaire durant ma première année de Master 1 Informatique que
      j'ai passée à l'université Rennes 1. Ce logiciel sera le fruit d'un travail d'un an par un group de 14
      étudiants dont moi, en réponse à la demande d'un client et sous la supervision d'un encadrant.</p>
    <h3>Le besoin du client:</h3>
    <p>Les enseignants dans les universités font face à un problème conséquent, celui de la perte de temps liée à la manipulation de copies lors de la correction. En effet, une grande partie du temps consacré à la correction d'un examen papier se concentre dans la manipulation de copies, et non dans l'évaluation des réponses. Hors, ce temps est compris dans le temps de travail rémunéré de l'enseignant et n'apporte rien au travail de celui-ci. Il existe déjà des solutions pouvant aider à réduire ce temps. <br/> Nous avons étudié trois d'entre elles que nous avons jugées pertinentes :</p>
    <h3>Moodle</h3>
    <p>Moodle est un outil en ligne répendu dans les universités françaises. Il permet la création d'examens en ligne et la correction de ceux-ci automatiquement. Moodle propose aussi d'envoyer la copie corrigée à l'élève par mail après la correction, de plus il est libre et gratuit. Cependant, les examens en ligne ne permettent pas de surveiller la fraude entre étudiants. Aussi, Moodle ne propose pas la possibilité de faire des schémas ou autre type de réponse plus complexe que du texte et l'interface de correction fonctionne sur la superposition de plusieurs fenêtres, ce qui est très contraignant et peu pratique à utiliser.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/scanexam/moodleInterface.png" about="Capture écran Moodle"
           alt="Capture écran Moodle">
      <figcaption>
        <a href="@/assets/portfolio/scanexam/moodleInterface.png" target="_blank">Capture d'écran de Moodle et ses multiples fenêtres.</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <h3>Auto-Multiple-Choice (AMC)</h3>
    <p>AMC est un logiciel de création et de correction automatique de questionnaires à choix multiples. Ce logiciel permet de créer un sujet et d'y ajouter une notion d'aléatoire dans l'ordre des questions et des réponses. Ce sujet peut ensuite être imprimé pour que les étudiants composent sur papier, permettant de résoudre la problématique de fraude citée précédemment. Une fois les copies ramassées, elles doivent être scannées pour qu'AMC puisse les corriger automatiquement. La correction automatique se fait uniquement sur les QCM, et même si le logiciel permet la création de question ouvertes, celles-ci doivent encore être corrigées à la main. Les copies corrigées peuvent être envoyées à l'étudiant via mail, et les notes de tous les étudiants peuvent être exportées vers un fichier type Excel pour l'administration. En revanche, le problème réside dans la création du sujet. Pour créer un sujet, il faut maîtriser le langage LaTeX, ce qui représente un obstacle conséquent pour les enseignants non-informaticiens. De plus, AMC n'est disponible que pour Linux et MacOS quand bien même ce logiciel est open-source et gratuit.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/scanexam/amcCode.png" about="Capture écran création sujet AMC"
           alt="Capture écran création sujet AMC">
      <figcaption>
        <a href="@/assets/portfolio/scanexam/amcCode.png" target="_blank">Capture d'écran lors de la création d'un sujet avec LaTeX.</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <h3>GradeScope</h3>
    <p>GradeScope est le troisième outil que nous avons étudié. Logiciel en ligne, GradeScope est efficace et bien conçu. Le modèle se fait sur un sujet PDF importé, où l'utilsateur peut séléctionner les questions et sous questions par un rectangle, définir un barème modifiable à tout moment, et bien d'autre. Le modèle sert ensuite lorsque les copies sont numérisées et uploadées sur le site à automatiser l'accès à la question voulue ou une autre. À tout moment, il est possible d'ajouter des annotations manuscrites et/ou textuelles sur une copie, de marquer une réponse pour la réutiliser en correction ou en cas d'autre besoin. GradeScope peut également envoyer les copies corrigées par mail à l'étudiant et exporter les notes dans un fichier type Excel. Là où GradeScope n'est pas idéal, c'est qu'il est obligatoire d'uploader les copies numérisées, ce qui peut prendre beaucoup de temps pour une grande promotion et qui soulève des question relatives à la privacité des données. De plus, GradeScope n'est pas libre de droit ni gratuit : à $5 par étudiant par examen, cela représente un coût important et supplémentaire pour l'université.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/scanexam/gradescopeSujet.png" about="Capture écran sujet GradeScope"
           alt="Capture écran sujet GradeScope">
      <figcaption>
        <a href="@/assets/portfolio/scanexam/gradescopeSujet.png" target="_blank">Capture d'écran lors de la création d'une question dans GradeScope.</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <figure class="figs">
      <img src="@/assets/portfolio/scanexam/gradescopeAnnotation.png" about="Capture écran annotaion GradeScope"
           alt="Capture écran annotation GradeScope">
      <figcaption>
        <a href="@/assets/portfolio/scanexam/gradescopeAnnotation.png" target="_blank">Capture d'écran avec des annotations sur une copie dans GradeScope.</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <p>Notre client nous a exprimé ses besoins, nous les avons étudiés puis nous lui avons proposé certaines fonctionnalités présentes dans les solutions éxistantes pour savoir si il souhaitait les ajouter au cahier des charges. Ansi, nous avons convenu d'une liste de fonctionnalités à implémenter dans ScanExam :</p>
    <ul class="articleUl">
      <li>Navigation entre questions et copies d'élèves</li>
      <li>Notation des questions</li>
      <li>Récapitulatif des notes</li>
      <li>Sauvegarder, importer, exporter un examen et/ou une correction en cours</li>
      <li>Association ID↔copie via QRCode</li>
      <li>Création zone de question par séléction sur le sujet</li>
      <li>Menus et raccourcis clavier</li>
      <li>Zoom/dézoom sur la copie/sujet</li>
      <li>Un PDF par élève</li>
      <li>Gestion de note par défaut</li>
      <li>Système de filtre de question</li>
      <li>Disponibilité d'un corrigé en parallèle</li>
      <li>Ajout d'annotations</li>
      <li>Navigation par question(s) non corrigée(s)</li>
      <li>Points bonus/malus</li>
      <li>Barème modifiable</li>
      <li>Envoi des notes par mail</li>
    </ul>
    <p>Le client avait une preuve de concept déjà créée, codée en XTend et Swing pour la librairie graphique. Nous avons donc étudié et utilisé cette base pour la suite de notre projet, en améliorant le pattern MVC partiellement présent en un pattern MVP. Nous avons aussi proposé au client un changement de Swing à JavaFX, plus agréable à travailler avec et plus moderne. Le client n'étant pas encore convaincu, nous créons les deux interfaces Swing et JavaFX et le client bloquera son choix à la livraison de la premiere version.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/scanexam/protoProf.png" about="Capture écran prototype du client"
           alt="Capture écran prototype du client">
      <figcaption>
        <a href="@/assets/portfolio/scanexam/protoProf.png" target="_blank">Capture d'écran du prototype du client.</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <p> En ce moment, le projet est encore en cours de développement. La première version ayant uniquement les fonctionnalités de base est prévue pour mi Mars. Après cette date, le développement des fonctionnalités restantes commencera, et une des deux librairies graphiques sera retirée en fonction du choix du client.</p>
    <img src="@/assets/doublevictorianBcg.png" class="separator" about="" alt="separateur">
  </div>
</template>

<script>
export default {
  name: "PortfolioScanExamComponent"
}
</script>


<style lang="scss" scoped>
.scanexam{
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    
    vertical-align: baseline;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /****/
  *{
    box-sizing: border-box;
    z-index: 1;
  }
  a{
    text-decoration: none;
  }

  a:hover{
    text-decoration: dotted #f48fb1 ;
  }
  a, a:visited, a:active{
    color: #F48FB1 ;
  }

  .material-icons.md-15{
    font-size: 15px;
    color: #f48fb1;
    vertical-align: middle;
  }
  .figs{
    margin-top: 10px;
  }
  .figs > img{
    width: auto;
    max-width: 100%;
    z-index: 1 !important;
  }
  .figs > figcaption > a{
    width: auto;
    border-bottom: solid 1px #f48fb1;
  }
  .illustration{
    min-width: 100px;
    /*width:25%;*/
    max-width: 400px;
    max-height: 150px;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  table{
    margin: 10px auto 16px;
    max-width: 100%;
    overflow-y: auto;
  }
  td, th{
    padding: 3px;
    border: #191919 solid 1px;
  }
  pre{
    min-width: 50%;
    width: auto;
    max-width: 80%;
    text-align: left;
    margin: auto;
    word-wrap: break-word;
    overflow-y: auto;
  }
  .sectionTitle{
    font-size: 2.5em;
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .separator{
    width: auto;
    opacity: 0.75;
    margin-top: 20px;
  }
  .green-check{
    color: #00b000 !important;
  }
  .nocode, .footer{
    color: #F9F9F9;
  }

  h3{
    font-size: 1.4em;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 768px) {/*1025*/

    .article{
      min-height: 100vh;
      min-width: 720px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .article{
      min-height: 100vh;
      min-width: 50vw;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 767px) {

    .article{
      text-align: center;
      text-indent: 2em;
    }
    .illustration{
      background-color: rgba(255, 255, 255, 0);
      min-width: 100px;
      width:50%;
      max-width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .aside{
      display: block;
      min-height: 35px;
      height: auto;
      max-height: 360px;
      width: 100vw;
      position: sticky;
      text-align: center;
      padding-bottom: 5px ;
      margin: 0 ;
      background-color: #191919;
      top: 0;
      left: 0;
      z-index: 9;
    }

    .article{
      text-align: center;
      min-height: 88vh;
      width: 100%;
      margin: 0;
      grid-row: 1/1;
      padding: 0 12px 13px;
    }

    pre{
      min-width: 50%;
      width: auto;
      max-width: 95%;
      max-height: 400px;
      text-align: left;
      margin: auto;
    }
    .separator{
      max-height: 20px;
    }
    .article:first-of-type > .sectionTitle{
      padding-top: 0;
    }
    .sectionTitle{
      padding-top: 25px;
    }
    tr{
      min-width: 150px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }


  }
  .article>p:not(.text-center){
    text-indent: 20px;
    text-align: justify !important;
  }
}
</style>
