<template>
  <div class="telemetrieF1 article portfolioTelemetrieF1Component">
    <h2 class="sectionTitle">Télemétrie F1</h2>
    <img src="@/assets/undraw/undraw_progressive_app_m9ms.svg" class="illustration" about="undraw.co" alt="illustration" title="undaw.co">
    <p>Cette petite application a été développée dans un cadre personnel dans le but d'apprendre le langage
      C++ et la programmation orientée objet avec le framework Qt Creator, en utilisant les jeux de simulation
      de Formule 1 de Codemasters qui donnent aux utilisateurs la possibilité d'avoir un
      suivi de la télemétrie des voitures. J'ai décidé de créer une petite application recréant l'affichage
      des informations les plus importantes comme sur le volant des voitures modernes réélles: régime
      moteur, vitesse, rapport, temps du tour, carburant...</p>
    <p>Le jeu formate les données et ajuste la fréquence d'envoi selon le choix de l'utilisateur (de 10Hz à
      60Hz). Les spécifications des paquets ont beaucoup changé entre les jeux, celle de 2017 était courte,
      mais avec les nouveaux jeux sortis chaque années, celle-ci s'est étoffée et est devenue très complète.
      Ces données sont envoyées par UDP à l'adresse IP spécifiée par l'utilisateur dans le jeu via différents
      paquets :</p>
    <table>
      <tr>
        <th>Packet type</th>
        <th>Frequency</th>
      </tr>
      <tr>
        <td>Motion</td>
        <td>User specified</td>
      </tr>
      <tr>
        <td>Lap Data</td>
        <td>User specified</td>
      </tr>
      <tr>
        <td>Session</td>
        <td>2/sec</td>
      </tr>
      <tr>
        <td>Event</td>
        <td>When occured</td>
      </tr>
      <tr>
        <td>Participant</td>
        <td>5 sec</td>
      </tr>
      <tr>
        <td>Car Setup</td>
        <td>2/sec</td>
      </tr>
      <tr>
        <td>Car Telemetry</td>
        <td>User specified</td>
      </tr>
      <tr>
        <td>Car Status</td>
        <td>User specified</td>
      </tr>
    </table>
    <p>Chaque paquet contient un en-tête permettant l'identification via un numéro, le format des paquets
      (différent selon l'année du jeu) et le numero de la voiture du joueur parmis toutes les autres.
      Grâce à ces informations, il est possible d'extraire les données voulues à leur réception. Par
      exemple pour le paquet de télemétrie :</p>
    <div class="prettify">
      <pre class="prettyprint">
<code class="language-cpp">
  struct CarTelemetryData
  {
      // Speed of car in kilometres per hour
      uint16    m_speed;
      // Amount of throttle applied (0.0 to 1.0)
      float     m_throttle;
      // Steering (-1.0 (full lock left) to 1.0 (full lock right))
      float     m_steer;
      // Amount of brake applied (0.0 to 1.0)
      float     m_brake;
      // Amount of clutch applied (0 to 100)
      uint8     m_clutch;
      // Gear selected (1-8, N=0, R=-1)
      int8      m_gear;
      // Engine RPM
      uint16    m_engineRPM;
      // 0 = off, 1 = on
      uint8     m_drs;
      // Rev lights indicator (percentage)
      uint8     m_revLightsPercent;
      // Brakes temperature (celsius)
      uint16    m_brakesTemperature[4];
      // Tyres surface temperature (celsius)
      uint16    m_tyresSurfaceTemperature[4];
      // Tyres inner temperature (celsius)
      uint16    m_tyresInnerTemperature[4];
      // Engine temperature (celsius)
      uint16    m_engineTemperature;
      // Tyres pressure (PSI)
      float     m_tyresPressure[4];
      // Driving surface
      uint8     m_surfaceType[4];
  };
  struct PacketHeader
  {
      // 2019
      uint16    m_packetFormat;
      // Game major version - "X.00"
      uint8     m_gameMajorVersion;
      // Game minor version - "1.XX"
      uint8     m_gameMinorVersion;
      // Version of this packet type, all start from 1
      uint8     m_packetVersion;
      // Identifier for the packet type
      uint8     m_packetId;
      // Unique identifier for the session
      uint64    m_sessionUID;
      // Session timestamp
      float     m_sessionTime;
      // Identifier for the frame the data was retrieved on
      uint      m_frameIdentifier;
      // Index of players car in the array
      uint8     m_playerCarIndex;
  };
  struct PacketCarTelemetryData
  {
      // Header
      PacketHeader    	m_header;
      CarTelemetryData	m_carTelemetryData[20];
      // Bit flags specifying which buttons are being pressed
      uint32              m_buttonStatus;
  };
</code>
      </pre>
    </div>
    <p>Ainsi, avec ce paquet reçu, il est possible d'obtenir les informations les plus importantes de toutes
      les voitures sur la piste, mais surtout de celle du joueur, dont la position dans le tableau
      est indiquée par m_playerCarIndex disponible dans tous les paquets via l'en-tête. De nombreuses autres
      données sont disponibles, comme les différents réglages appliqués à la voiture (uniquement du joueur)
      permettant de faire une comparaison détaillée de la performance à chaque instant du tour pour de
      multiples réglages et determiner le meilleur pour établir un record ou gagner la course.
      Il est aussi possible d'obtenir l'usure des pneus, l'usure des composants moteur, les éventuels
      dégats physiques, les forces laterales et longitudinales, les drapeaux des commissaires de course et
      bien d'autres.</p>
    <p>L'interface du logiciel PC est consituée de deux zones, celle de gacuhe comporte plusieurs graphiques,
      permettant de suivre différentes données tout au long du circuit, et celle de droite comporte les
      informations générales, comme l'écurie, le type de pneus ainsi que leur usure et température, les
      temps aux tours, un accéléromètre, la météo, l'utilisation des pédales, le numero du pilote et la
      position sur le circuit.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/F1/PC.png" about="Capture logiciel PC" alt="Capture logiciel PC">
      <figcaption>
        <a href="@/assets/portfolio/F1/PC.png" target="_blank">Capture logiciel PC</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <p>L'interface de l'application Android est plus simple, un premier panneau affiche un équivalent de ce
      que peut voir le pilote sur son volant, et un deuxième panneau accessible par un slide lateral
      affiche les informations de météo, voiture, numero de pilote, pneus, temps au tour, et position sur le
      circuit. Les graphiques ne sont pas intgrés puisqu'ils seraient difficiles à lire, et leur utilité
      réduite. Cette application est plus orientée pour un affichage de substitution pour le pilote plutot
      qu'un suivi détaillé que pourraient avoir besoin des inégnieurs de course dans les stands.</p>
    <figure class="figs">
      <img src="@/assets/portfolio/F1/Android.png" about="Capture application Android" alt="Capture application Android">
      <figcaption>
        <a href="@/assets/portfolio/F1/Android.png" target="_blank">Capture application Android</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <figure class="figs">
      <img src="@/assets/portfolio/F1/Android2.png" about="Capture application Android panneau 2"
           alt="Capture application Android panneau 2">
      <figcaption>
        <a href="@/assets/portfolio/F1/Android2.png" target="_blank">Capture application Android panneau 2</a>
        <i class="material-icons md-15">launch</i>
      </figcaption>
    </figure>
    <img src="@/assets/doublevictorianBcg.png" class="separator" about="" alt="separateur">
  </div>
</template>

<script src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js"></script>

<script lang="js">
export default {
  name: "PortfolioTelemetrieF1Component"
}
</script>


<style lang="css">
/* Pretty printing styles. Used with prettify.js. */
/* Vim sunburst theme by David Leibovic */

pre .str, code .str { color: #65B042; } /* string  - green */
pre .kwd, code .kwd { color: #E28964; } /* keyword - dark pink */
pre .com, code .com { color: #AEAEAE; font-style: italic; } /* comment - gray */
pre .typ, code .typ { color: #89bdff; } /* type - light blue */
pre .lit, code .lit { color: #3387CC; } /* literal - blue */
pre .pun, code .pun { color: #fff; } /* punctuation - white */
pre .pln, code .pln { color: #fff; } /* plaintext - white */
pre .tag, code .tag { color: #89bdff; } /* html/xml tag    - light blue */
pre .atn, code .atn { color: #bdb76b; } /* html/xml attribute name  - khaki */
pre .atv, code .atv { color: #65B042; } /* html/xml attribute value - green */
pre .dec, code .dec { color: #3387CC; } /* decimal - blue */

pre.prettyprint, code.prettyprint {
  background-color: #000;
  border-radius: 8px;
}

pre.prettyprint {
  width: 95%;
  margin: 1em auto;
  padding: 1em;
  white-space: pre-wrap;
}


/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE; } /* IE indents via margin-left */
li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,li.L3,li.L5,li.L7,li.L9 { }

@media print {
  pre .str, code .str { color: #060; }
  pre .kwd, code .kwd { color: #006; font-weight: bold; }
  pre .com, code .com { color: #600; font-style: italic; }
  pre .typ, code .typ { color: #404; font-weight: bold; }
  pre .lit, code .lit { color: #044; }
  pre .pun, code .pun { color: #440; }
  pre .pln, code .pln { color: #000; }
  pre .tag, code .tag { color: #006; font-weight: bold; }
  pre .atn, code .atn { color: #404; }
  pre .atv, code .atv { color: #060; }
}
</style>

<style lang="scss" scoped>
.telemetrieF1{

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;

    vertical-align: baseline;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /****/
  *{
    box-sizing: border-box;
    z-index: 1;
  }
  a{
    text-decoration: none;
  }

  a:hover{
    text-decoration: dotted #f48fb1 ;
  }
  a, a:visited, a:active{
    color: #F48FB1 ;
  }

  .material-icons.md-15{
    font-size: 15px;
    color: #f48fb1;
    vertical-align: middle;
  }
  .figs{
    margin-top: 10px;
  }
  .figs > img{
    width: auto;
    max-width: 100%;
    z-index: 1 !important;
  }
  .figs > figcaption > a{
    width: auto;
    border-bottom: solid 1px #f48fb1;
  }
  .illustration{
    min-width: 100px;
    /*width:25%;*/
    max-width: 400px;
    max-height: 150px;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  table{
    margin: 10px auto 16px;
    max-width: 100%;
    overflow-y: auto;
  }
  td, th{
    padding: 3px;
    border: #191919 solid 1px;
  }
  pre{
    min-width: 50%;
    width: auto;
    max-width: 80%;
    text-align: left;
    margin: auto;
    word-wrap: break-word;
    overflow-y: auto;
  }
  .sectionTitle{
    font-size: 2.5em;
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .separator{
    width: auto;
    opacity: 0.75;
    margin-top: 20px;
  }
  .green-check{
    color: #00b000 !important;
  }
  .nocode, .footer{
    color: #F9F9F9;
  }

  h3{
    font-size: 1.4em;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 768px) {/*1025*/

    .article{
      min-height: 100vh;
      min-width: 720px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .article{
      min-height: 100vh;
      min-width: 50vw;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 20px;
    }

    .separator{
      max-height: 25px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 50%;
      max-width: 80%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }
    pre{
      max-height: 400px;
    }

  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 767px) {

    .article{
      text-align: center;
      text-indent: 2em;
    }
    .illustration{
      background-color: rgba(255, 255, 255, 0);
      min-width: 100px;
      width:50%;
      max-width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .aside{
      display: block;
      min-height: 35px;
      height: auto;
      max-height: 360px;
      width: 100vw;
      position: sticky;
      text-align: center;
      padding-bottom: 5px ;
      margin: 0 ;
      background-color: #191919;
      top: 0;
      left: 0;
      z-index: 9;
    }

    .article{
      text-align: center;
      min-height: 88vh;
      width: 100%;
      margin: 0;
      grid-row: 1/1;
      padding: 0 12px 13px;
    }

    pre{
      min-width: 50%;
      width: auto;
      max-width: 95%;
      max-height: 400px;
      text-align: left;
      margin: auto;
    }
    .separator{
      max-height: 20px;
    }
    .article:first-of-type > .sectionTitle{
      padding-top: 0;
    }
    .sectionTitle{
      padding-top: 25px;
    }
    tr{
      min-width: 150px;
    }
    .figs{
      padding: 2px;
      min-width: 200px;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: 5px;
    }


  }
  .article>p:not(.text-center){
    text-indent: 20px;
    text-align: justify !important;
  }
}
</style>
